<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-alert
            v-if="errorMessage"
            :value="errorMessage !== null"
            type="error"
          >
            {{ errorMessage }}
          </v-alert>
          <v-card class="elevation-12">
            <v-toolbar
              dark
              color="primary"
            >
              <v-toolbar-title>Systent - Bewertung</v-toolbar-title>
              <v-spacer />
              <v-avatar
                size="32px"
                tile
              >
                <img
                  src="@/assets/icon.png"
                  alt="Systent Bewertung"
                >
              </v-avatar>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  id="username"
                  ref="usr"
                  v-model="user"
                  :disabled="spinning"
                  :label="'Benutzername'"
                  name="login"
                  prepend-icon="mdi-account"
                  autocomplete="asix login username"
                  autocapitalize="none"
                  type="text"
                  @keyup.enter="setFocus()"
                />
                <v-text-field
                  id="password"
                  ref="pwd"
                  v-model="password"
                  :disabled="spinning"
                  :label="'Passwort'"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  prepend-icon="mdi-lock"
                  autocomplete="asix login password"
                  @keyup.enter="setFocus()"
                  @click:append="showPassword = !showPassword"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :disabled="isSubmitDisabled"
                :loading="spinning"
                color="primary"
                name="submit"
                @click="login()"
              >
                Anmelden
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import api from "@/utils/api"
import axios from 'axios'

export default {
  name: 'Login',
  data: function () {
    return {
      user: '',
      password: '',
      token: '',
      programDate: null,
      errorMessage: null,
      showPassword: false,
      autofilled: false,
      spinning: false,
      userLabel: 'Benutzername',
      passwordLabel: 'Passwort',
      redirectTo: '/themen'
      // redirectTo: '/abschluss',
    }
  },
  computed: {
    showLoadingSpinner: function () {
      return this.spinning
    },
    isSubmitDisabled () {
      if (this.autofilled) {
        return false
      } else if (this.user && this.password) {
        return false
      }
      return true
    }
  },
  async mounted () {
    this.errorMessage = null
    this.programDate = localStorage.getItem('programDate')
    this.removeLoginData()
  },
  methods: {
    setFocus: function () {
      if (!this.password) {
        this.$nextTick(this.$refs.pwd.focus())
      } else if (!this.user) {
        this.$nextTick(this.$refs.usr.focus())
      } else {
        this.login()
      }
    },
    removeLoginData: function () {
      localStorage.removeItem('token')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_first_name')
      localStorage.removeItem('user_last_name')
      localStorage.removeItem('username')
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('mitarbeiter_id')
      localStorage.removeItem('programDate')
      //setzt Rechte im Store auf false  => isAdmin und isSuperUser und isVerwaltung
      this.$store.commit("clearRechte")
    },
    saveLoginData: function (responseData) {
      localStorage.setItem('token', responseData.token)
      localStorage.setItem('user_id', responseData.user_id)
      localStorage.setItem('user_first_name', responseData.first_name)
      localStorage.setItem('user_last_name', responseData.last_name)
      localStorage.setItem('username', responseData.username)
      localStorage.setItem('mitarbeiter_id', responseData.mitarbeiter_id)
      localStorage.setItem('isAuthenticated', true)
      if (this.programDate) {
        localStorage.setItem('programDate', this.programDate)
      } else {
        const d = new Date()
        localStorage.setItem('programDate', `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`)
      }
    },
    login: async function () {
      this.spinning = true
      this.errorMessage = ''
      this.removeLoginData()
      const responseData = await this.verifyLogin()
      this.token = ''
      if (responseData) {
        this.saveLoginData(responseData.data)
        this.checklastsync()
        this.routeToDefaultPage()
      } else {
        this.removeLoginData()
        this.spinning = false
      }
    },
    verifyLogin: async function () {
      const url = '/api/login/'
      try {
        const response = await axios.post(url, { username: this.user, password: this.password })
        this.errorMessage = ''
        return response
      } catch (error) {
        if (error.response) {
          if (error.response.status == 401) {
            this.errorMessage = 'Ungültige Benutzer/Passwort kombination'
          } else if(error.response.status >= 500){
            this.errorMessage = 'Ein unkontrollierter Fehler ist aufgetreten!'
          } else if(error.response.data.message){
            this.errorMessage = error.response.data.message
          } else {
            this.error = 'Ein unbekannter Fehler ist aufgetreten!'
          }
        } else if (error.request) {
          console.log(error.request)
        } else {
          console.log('Error', error.message)
        }
        return false
      }
    },
    routeToDefaultPage: function () {
      api.get("/api/info/zeitraum").then(response=> {
        if(response.themenvergabe){
          this.$router.push({ path: "/themen" })
        }else if(response.punktevergabe) {
          this.$router.push({ path: "/abschluss"})
        }else{
          this.$router.push({ path: "/uebersichtpunkte"})
        }
      }).catch(error=> {
        this.error = error
        console.log(error)
      })
    },
    async mitarbeiterRefresh () {
      const refreshAPI = `/api/mitarbeiter/refresh`
      try{
        await api.get(refreshAPI)
      }catch(error){
        console.log(error)
      }

    },
    async checklastsync(){
      const url = '/api/mitarbeiter/syncdate'
      try{
        await api.post(url)
      }catch(err) {
        console.log(err)
        return false
      }

    }
  }
}
</script>
